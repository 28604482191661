export default function Electricity(props){
    return(
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={props.width} height={props.height} viewBox="0 0 1239.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<metadata>
Created by potrace 1.15, written by Peter Selinger 2001-2017
</metadata>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill={props.fill} stroke="none">
<path d="M11835 12784 c-781 -27 -3602 -114 -3676 -114 l-74 0 -264 -256
c-153 -148 -269 -253 -277 -250 -7 3 -84 21 -171 40 -1381 305 -2804 126
-4038 -508 -562 -290 -998 -607 -1440 -1050 -290 -291 -481 -521 -685 -826
-495 -743 -780 -1534 -882 -2450 -17 -155 -17 -845 0 -1000 113 -1017 460
-1902 1058 -2697 76 -101 92 -129 82 -138 -7 -7 -60 -57 -118 -112 l-106 -100
96 -7 c52 -3 149 -6 216 -6 l121 0 219 -218 c181 -180 445 -415 545 -485 21
-15 17 -21 -227 -277 -1747 -1837 -2201 -2315 -2199 -2318 1 -2 787 414 1746
924 959 509 1767 938 1794 951 l50 25 131 -56 c1206 -508 2569 -604 3844 -270
1412 370 2633 1248 3390 2439 238 373 446 814 578 1226 176 547 254 1043 254
1619 0 822 -176 1580 -537 2315 -275 560 -614 1033 -1048 1466 -59 59 -107
110 -106 115 0 5 504 457 1120 1004 616 547 1127 1003 1136 1013 19 20 50 20
-532 1z m-5384 -584 c300 -22 583 -63 848 -121 74 -16 136 -30 137 -32 2 -2
-1021 -997 -2272 -2211 -1251 -1215 -2273 -2210 -2271 -2212 2 -2 611 -24
1353 -49 742 -25 1368 -46 1392 -47 l43 -3 -418 -396 c-230 -217 -1151 -1089
-2046 -1937 -896 -848 -1632 -1542 -1636 -1542 -10 0 -176 226 -268 365 -127
191 -199 315 -313 540 -303 598 -477 1210 -541 1905 -16 174 -16 646 0 820 51
551 175 1061 377 1555 68 164 244 515 334 665 514 853 1215 1523 2105 2009
705 386 1484 616 2320 686 186 16 675 18 856 5z m3705 -1683 c348 -355 612
-708 866 -1157 131 -230 287 -588 383 -875 350 -1052 350 -2178 0 -3230 -96
-287 -252 -645 -383 -875 -188 -332 -374 -599 -610 -880 -130 -153 -437 -462
-592 -594 -798 -681 -1758 -1125 -2807 -1300 -323 -54 -603 -76 -962 -76 -613
0 -1179 87 -1761 269 -133 41 -393 136 -480 174 l-53 23 1879 998 c1034 549
1883 1002 1888 1007 5 4 -43 6 -110 4 -156 -6 -2664 -28 -2669 -24 -1 2 1272
974 2830 2159 l2834 2155 -1525 6 c-839 4 -1527 8 -1529 10 -2 3 552 499 1232
1104 680 605 1275 1135 1322 1177 47 43 89 78 92 78 3 0 73 -69 155 -153z
m-7616 -7224 c294 -5 543 -8 553 -7 9 0 17 -2 17 -6 0 -6 -404 -437 -513 -547
l-42 -42 -100 80 c-209 168 -575 506 -575 531 0 7 24 8 63 4 34 -3 303 -9 597
-13z"/>
</g>
</svg>

    )
}